import { Route } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

export const appRoutes: Route[] = [
  {
    path: `:lang`,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'about',
        loadComponent: () =>
          import('./pages/about/about.component').then(c => c.AboutComponent),
      },
      {
        path: 'features',
        loadComponent: () =>
          import('./pages/features/features.component').then(
            c => c.FeaturesComponent
          ),
      },
      {
        path: 'become-supplier',
        loadComponent: () =>
          import('./pages/become-supplier/become-supplier.component').then(
            c => c.BecomeSupplierComponent
          ),
      },
      {
        path: 'contact-us',
        loadComponent: () =>
          import('./pages/contact-us/contact-us.component').then(
            c => c.ContactUsComponent
          ),
      },
      {
        path: 'presentation',
        loadComponent: () =>
          import('./pages/presentation/presentation.component').then(
            m => m.PresentationComponent
          ),
        title: 'Presentation',
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/' + (localStorage.getItem('lang') ?? 'en'),
    pathMatch: 'full',
  },
];
