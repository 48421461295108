import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TopNavbarComponent } from './common/components/top-navbar/top-navbar.component';
import { FooterComponent } from './common/components/footer/footer.component';

@Component({
  imports: [RouterOutlet, TopNavbarComponent, FooterComponent],
  selector: 'mars-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {}
