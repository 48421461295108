import { Component } from '@angular/core';
import { HeroComponent } from './hero/hero.component';
import { WhyJoinComponent } from './sections/why-join/why-join.component';
import { JoinSuppliersNetworkComponent } from './sections/join-suppliers-network/join-suppliers-network.component';
import { HowItWorksComponent } from './sections/how-it-works/how-it-works.component';
import { ContactUsComponent } from '../../common/components/contact-us/contact-us.component';

@Component({
  selector: 'mars-home',
  imports: [
    HeroComponent,
    WhyJoinComponent,
    JoinSuppliersNetworkComponent,
    HowItWorksComponent,
    ContactUsComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {}
