<nav aria-label="navigation" class="at-py-xl at-bg-white">
  <div
    class="nav-content-container at-flex at-justify-between at-align-center at-px-lg md:at-px-6xl xl:at-px-9xl">
    <!-- Logo -->
    <a [routerLink]="'home' | navigation">
      <svg
        width="133"
        height="40"
        viewBox="0 0 133 40"
        fill="none"
        class="at-inline-block"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.95498 27.6767C7.26581 27.3085 6.72289 26.7599 6.32799 26.0306C5.93251 25.3014 5.73535 24.4246 5.73535 23.3989V17.7027C5.73535 16.1578 6.15892 14.9628 7.00664 14.1183C7.85377 13.2738 8.9671 12.8516 10.346 12.8516H20.2355V15.1257H10.6046C9.85752 15.1257 9.22919 15.2957 8.71904 15.6344C8.20889 15.9737 7.9544 16.613 7.9544 17.5516V23.5295C7.9544 24.4681 8.20947 25.1073 8.71904 25.4461C9.22861 25.7854 9.85752 25.9553 10.6046 25.9553H20.2355V28.2295H10.346C9.44098 28.2295 8.64416 28.0454 7.9544 27.6773L7.95498 27.6767Z"
          fill="#06080D" />
        <path
          d="M23.6629 26.9625C22.8152 26.1175 22.3916 24.923 22.3916 23.3781V17.7037C22.3916 16.1588 22.8152 14.9638 23.6629 14.1193C24.51 13.2748 25.6233 12.8525 27.0023 12.8525H33.6384C34.5434 12.8525 35.3437 13.0401 36.0405 13.4153C36.7373 13.7911 37.2826 14.3433 37.678 15.072C38.0729 15.8012 38.2707 16.6786 38.2707 17.7037V23.3781C38.2707 24.923 37.843 26.118 36.9889 26.9625C36.1341 27.807 35.0173 28.2293 33.6384 28.2293H27.0023C25.6233 28.2293 24.51 27.807 23.6629 26.9625ZM35.287 25.4464C35.7966 25.1077 36.0516 24.4685 36.0516 23.5299V17.552C36.0516 16.6134 35.7966 15.9747 35.287 15.6348C34.7768 15.296 34.1485 15.1261 33.402 15.1261H27.2614C26.5144 15.1261 25.886 15.296 25.3759 15.6348C24.8657 15.9741 24.6112 16.6134 24.6112 17.552V23.5299C24.6112 24.4685 24.8663 25.1077 25.3759 25.4464C25.8854 25.7858 26.5144 25.9557 27.2614 25.9557H33.402C34.1485 25.9557 34.7774 25.7864 35.287 25.4464Z"
          fill="#06080D" />
        <path
          d="M52.3834 27.8603C51.8088 27.5568 51.3239 27.0881 50.929 26.4524C50.5335 25.8173 50.1427 24.9434 49.7548 23.8319L47.665 17.7246C47.3772 16.8442 47.0437 16.1832 46.6629 15.7427C46.282 15.3023 45.7759 15.0823 45.1441 15.0823C44.4116 15.0823 43.8658 15.2446 43.5066 15.5692C43.1474 15.8939 42.9139 16.2914 42.8063 16.7607C42.6987 17.23 42.6448 17.8257 42.6448 18.5473V28.2284H40.4258V17.5293C40.4258 16.0568 40.8172 14.8947 41.6 14.0426C42.3827 13.191 43.5499 12.7646 45.1014 12.7646C46.0351 12.7646 46.8068 12.9634 47.4176 13.3604C48.0278 13.7579 48.5163 14.2884 48.8825 14.9523C49.2487 15.6169 49.6044 16.4614 49.949 17.4864L51.9528 23.4208C52.1821 24.0854 52.412 24.6011 52.6419 24.9692C52.8719 25.3374 53.1445 25.6009 53.461 25.7596C53.7769 25.919 54.1788 25.9984 54.6673 25.9984C55.5145 25.9984 56.0966 25.7273 56.4125 25.1857C56.7284 24.6446 56.8864 23.8107 56.8864 22.6839V12.8511H59.1054V22.9009C59.1054 24.7922 58.7316 26.1678 57.9851 27.027C57.238 27.8862 56.103 28.3161 54.5808 28.3161C53.6897 28.3161 52.9573 28.1643 52.3828 27.8609L52.3834 27.8603Z"
          fill="#06080D" />
        <path
          d="M66.4746 15.1257H60.3984V12.8516H74.7699V15.1257H68.7153V28.2289H66.4746V15.1257Z"
          fill="#06080D" />
        <path
          d="M77.3336 14.1193C78.1807 13.2748 79.294 12.8525 80.673 12.8525H87.3307C88.7096 12.8525 89.8265 13.2354 90.6812 14.0005C91.5354 14.7662 91.963 15.8777 91.963 17.3361C91.963 18.7946 91.55 19.9713 90.7239 20.6929C89.8978 21.4151 88.8243 21.7756 87.5027 21.7756H86.3391C85.592 21.7756 85.0064 21.9379 84.5834 22.2631C84.1598 22.5883 83.9474 23.1188 83.9474 23.8551C83.9474 24.649 84.2025 25.1983 84.7127 25.5011C85.2222 25.8046 85.8868 25.9563 86.7053 25.9563H91.7688V28.2305H86.2958C84.9601 28.2305 83.8608 27.8658 82.9991 27.1366C82.1373 26.4074 81.7062 25.3141 81.7062 23.8557C81.7062 22.4843 82.0905 21.4122 82.8587 20.6394C83.6268 19.8673 84.7226 19.4809 86.1442 19.4809H87.4588C88.062 19.4809 88.5862 19.3292 89.0314 19.0263C89.4766 18.7228 89.6995 18.153 89.6995 17.315C89.6995 16.4769 89.448 15.9035 88.9454 15.593C88.4423 15.2825 87.8391 15.1273 87.1353 15.1273H80.9298C80.1827 15.1273 79.5544 15.2972 79.0442 15.636C78.5341 15.9753 78.2796 16.6216 78.2796 17.5743V28.2305H76.0605V17.7043C76.0605 16.1594 76.4841 14.9644 77.3318 14.1199L77.3336 14.1193Z"
          fill="#06080D" />
        <path
          d="M113.487 14.1193C114.335 13.2748 115.448 12.8525 116.827 12.8525H123.528C124.433 12.8525 125.233 13.0401 125.93 13.4153C126.627 13.7911 127.172 14.3433 127.568 15.072C127.962 15.8012 128.16 16.6786 128.16 17.7037V28.2299H125.941V17.5525C125.941 16.614 125.686 15.9753 125.177 15.6354C124.666 15.2966 124.038 15.1267 123.292 15.1267H117.086C116.339 15.1267 115.711 15.2966 115.2 15.6354C114.69 15.9747 114.436 16.614 114.436 17.5525V28.2299H112.217V17.7037C112.217 16.1588 112.64 14.9638 113.488 14.1193H113.487Z"
          fill="#06080D" />
        <path
          d="M123.721 21.4717H116.676V23.7023H123.721V21.4717Z"
          fill="#06080D" />
        <path
          d="M95.3996 14.1193C96.2467 13.2748 97.3601 12.8525 98.739 12.8525H105.44C106.345 12.8525 107.145 13.0401 107.842 13.4153C108.539 13.7911 109.084 14.3433 109.48 15.072C109.875 15.8012 110.072 16.6786 110.072 17.7037V28.2299H107.853V17.5525C107.853 16.614 107.598 15.9753 107.089 15.6354C106.578 15.2966 105.95 15.1267 105.204 15.1267H98.9982C98.2511 15.1267 97.6227 15.2966 97.1126 15.6354C96.6024 15.9747 96.348 16.614 96.348 17.5525V28.2299H94.1289V17.7037C94.1289 16.1588 94.5525 14.9638 95.4002 14.1193H95.3996Z"
          fill="#06080D" />
        <path
          d="M105.634 21.4717H98.5889V23.7023H105.634V21.4717Z"
          fill="#06080D" />
      </svg>
    </a>

    <!-- links -->
    <ul class="at-none xl:at-flex at-gap-4xl at-text-sm at-fw-medium">
      <li>
        <a
          class="at-text-primary-500"
          [routerLink]="'about' | navigation"
          routerLinkActive="active-route">
          {{ 'top_navbar_section_about_link' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="at-text-primary-500"
          [routerLink]="'features' | navigation"
          routerLinkActive="active-route">
          {{ 'top_navbar_section_features_link' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="at-text-primary-500"
          [routerLink]="'become-supplier' | navigation"
          routerLinkActive="active-route">
          {{ 'top_navbar_section_become_a_supplier_link' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="at-text-primary-500"
          [routerLink]="'contact-us' | navigation"
          routerLinkActive="active-route">
          {{ 'top_navbar_section_contact_us_link' | transloco }}
        </a>
      </li>
    </ul>

    <!-- lang -->
    <div class="at-flex at-gap-lg">
      <button
        type="button"
        (click)="switchLanguage()"
        class="at-flex at-gap-sm at-align-center">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#0A0B09"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
            stroke="#0A0B09"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M15 3C16.95 8.84 16.95 15.16 15 21"
            stroke="#0A0B09"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
            stroke="#0A0B09"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M3 8.99961C8.84 7.04961 15.16 7.04961 21 8.99961"
            stroke="#0A0B09"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>

        <span class="at-ff-main-ar">{{ lang() === 'en' ? 'عربي' : 'EN' }}</span>

        <!-- <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.9465 5.45312H7.79316H4.05317C3.41317 5.45312 3.09317 6.22646 3.5465 6.67979L6.99983 10.1331C7.55317 10.6865 8.45317 10.6865 9.0065 10.1331L10.3198 8.81979L12.4598 6.67979C12.9065 6.22646 12.5865 5.45312 11.9465 5.45312Z"
            fill="#111827" />
        </svg> -->
      </button>

      <!-- Action Buttons -->
      <!-- {{ 'top_navbar_login_button_label' | transloco }} -->
      <!-- {{ 'top_navbar_sign_up_button_label' | transloco }} -->
      <!-- <div class="at-none md:at-flex at-gap-md">
        <a
          class="at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-border at-border-neutral-300 at-radius-sm at-text-neutral-700 at-fw-medium">
          Login
        </a>
        <a
          class="at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-primary-500 at-radius-sm at-text-white at-fw-medium">
          Sign up
        </a>
      </div> -->

      <!-- menu side mobile nav -->
      <button
        (click)="toggleMobileNavbar()"
        type="button"
        class="at-inline-block xl:at-none">
        @if (!isMobileNavbarOpened) {
          <svg
            class="at-inline-block"
            width="44"
            height="40"
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M29.5 16.458H14.5C14.1583 16.458 13.875 16.1747 13.875 15.833C13.875 15.4913 14.1583 15.208 14.5 15.208H29.5C29.8417 15.208 30.125 15.4913 30.125 15.833C30.125 16.1747 29.8417 16.458 29.5 16.458Z"
              fill="#06080D" />
            <path
              d="M29.5 20.625H14.5C14.1583 20.625 13.875 20.3417 13.875 20C13.875 19.6583 14.1583 19.375 14.5 19.375H29.5C29.8417 19.375 30.125 19.6583 30.125 20C30.125 20.3417 29.8417 20.625 29.5 20.625Z"
              fill="#06080D" />
            <path
              d="M29.5 24.792H14.5C14.1583 24.792 13.875 24.5087 13.875 24.167C13.875 23.8253 14.1583 23.542 14.5 23.542H29.5C29.8417 23.542 30.125 23.8253 30.125 24.167C30.125 24.5087 29.8417 24.792 29.5 24.792Z"
              fill="#06080D" />
          </svg>
        }

        @if (isMobileNavbarOpened) {
          <svg
            class="at-inline-block"
            width="44"
            height="40"
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.094 23.9779L18.0229 16.9069C17.7813 16.6653 17.7813 16.2646 18.0229 16.023C18.2645 15.7814 18.6652 15.7814 18.9068 16.023L25.9779 23.0941C26.2194 23.3357 26.2194 23.7364 25.9779 23.9779C25.7363 24.2195 25.3356 24.2195 25.094 23.9779Z"
              fill="#06080D" />
            <path
              d="M18.0221 23.9779C17.7806 23.7364 17.7806 23.3357 18.0221 23.0941L25.0932 16.023C25.3348 15.7814 25.7355 15.7814 25.9771 16.023C26.2187 16.2646 26.2187 16.6653 25.9771 16.9069L18.906 23.9779C18.6644 24.2195 18.2637 24.2195 18.0221 23.9779Z"
              fill="#06080D" />
          </svg>
        }
      </button>
    </div>
  </div>
</nav>

@if (isMobileNavbarOpened) {
  <nav class="xl:at-none at-block mobile-navbar" aria-label="Site mobile menu">
    <div class="at-mb-xl">
      <!-- links -->
      <ul
        class="at-flex at-flex-column at-text-center at-gap-4xl at-text-md at-fw-medium at-px-2xl">
        <li>
          <a
            class="at-text-primary-500"
            (click)="isMobileNavbarOpened = false"
            [routerLink]="'about' | navigation"
            routerLinkActive="active-route">
            {{ 'top_navbar_section_about_link' | transloco }}
          </a>
        </li>
        <li>
          <a
            class="at-text-primary-500"
            (click)="isMobileNavbarOpened = false"
            [routerLink]="'features' | navigation"
            routerLinkActive="active-route">
            {{ 'top_navbar_section_features_link' | transloco }}
          </a>
        </li>
        <li>
          <a
            class="at-text-primary-500"
            (click)="isMobileNavbarOpened = false"
            [routerLink]="'become-supplier' | navigation"
            routerLinkActive="active-route">
            {{ 'top_navbar_section_become_a_supplier_link' | transloco }}
          </a>
        </li>
        <li>
          <a
            class="at-text-primary-500"
            (click)="isMobileNavbarOpened = false"
            [routerLink]="'contact-us' | navigation"
            routerLinkActive="active-route">
            {{ 'top_navbar_section_contact_us_link' | transloco }}
          </a>
        </li>

        <!-- Action Buttons -->
        <!-- <li class="at-block md:at-none">
          <div class="at-flex at-justify-between at-gap-md">
            <a
              class="at-w-100 at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-border at-border-neutral-300 at-radius-sm at-text-neutral-700 at-fw-medium">
              Login
            </a>
            <a
              class="at-w-100 at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-primary-500 at-radius-sm at-text-white at-fw-medium">
              Sign up
            </a>
          </div>
        </li> -->
      </ul>
    </div>
  </nav>
}
