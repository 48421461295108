<footer class="at-py-7xl">
  <div class="at-px-lg md:at-px-6xl xl:at-px-9xl">
    <div
      class="top-section at-flex at-flex-column at-justify-between at-border-bottom at-border-neutral-300 at-mb-2xl">
      <div class="at-mb-2xl">
        <!-- Logo -->
        <div class="svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="133"
            height="40"
            viewBox="0 0 133 40"
            fill="none">
            <path
              d="M7.95498 27.6767C7.26581 27.3085 6.72289 26.7599 6.32799 26.0306C5.93251 25.3014 5.73535 24.4246 5.73535 23.3989V17.7027C5.73535 16.1578 6.15892 14.9628 7.00664 14.1183C7.85377 13.2738 8.9671 12.8516 10.346 12.8516H20.2355V15.1257H10.6046C9.85752 15.1257 9.22919 15.2957 8.71904 15.6344C8.20889 15.9737 7.9544 16.613 7.9544 17.5516V23.5295C7.9544 24.4681 8.20947 25.1073 8.71904 25.4461C9.22861 25.7854 9.85752 25.9553 10.6046 25.9553H20.2355V28.2295H10.346C9.44098 28.2295 8.64416 28.0454 7.9544 27.6773L7.95498 27.6767Z"
              fill="#06080D" />
            <path
              d="M23.6629 26.9621C22.8152 26.117 22.3916 24.9226 22.3916 23.3777V17.7032C22.3916 16.1583 22.8152 14.9633 23.6629 14.1188C24.51 13.2743 25.6233 12.8521 27.0023 12.8521H33.6384C34.5434 12.8521 35.3437 13.0397 36.0405 13.4149C36.7373 13.7906 37.2826 14.3429 37.678 15.0715C38.0729 15.8007 38.2707 16.6782 38.2707 17.7032V23.3777C38.2707 24.9226 37.843 26.1176 36.9889 26.9621C36.1341 27.8065 35.0173 28.2288 33.6384 28.2288H27.0023C25.6233 28.2288 24.51 27.8065 23.6629 26.9621ZM35.287 25.446C35.7966 25.1072 36.0516 24.468 36.0516 23.5294V17.5515C36.0516 16.6129 35.7966 15.9742 35.287 15.6343C34.7768 15.2956 34.1485 15.1256 33.402 15.1256H27.2614C26.5144 15.1256 25.886 15.2956 25.3759 15.6343C24.8657 15.9736 24.6112 16.6129 24.6112 17.5515V23.5294C24.6112 24.468 24.8663 25.1072 25.3759 25.446C25.8854 25.7853 26.5144 25.9552 27.2614 25.9552H33.402C34.1485 25.9552 34.7774 25.7859 35.287 25.446Z"
              fill="#06080D" />
            <path
              d="M52.3834 27.8608C51.8088 27.5573 51.3239 27.0886 50.929 26.4529C50.5335 25.8178 50.1427 24.9439 49.7548 23.8324L47.665 17.7251C47.3772 16.8447 47.0437 16.1837 46.6629 15.7432C46.282 15.3027 45.7759 15.0828 45.1441 15.0828C44.4116 15.0828 43.8658 15.2451 43.5066 15.5697C43.1474 15.8944 42.9139 16.2919 42.8063 16.7612C42.6987 17.2305 42.6448 17.8262 42.6448 18.5478V28.2289H40.4258V17.5298C40.4258 16.0573 40.8172 14.8952 41.6 14.0431C42.3827 13.1915 43.5499 12.7651 45.1014 12.7651C46.0351 12.7651 46.8068 12.9639 47.4176 13.3609C48.0278 13.7584 48.5163 14.2889 48.8825 14.9528C49.2487 15.6174 49.6044 16.4619 49.949 17.4869L51.9528 23.4213C52.1821 24.0858 52.412 24.6016 52.6419 24.9697C52.8719 25.3379 53.1445 25.6013 53.461 25.7601C53.7769 25.9195 54.1788 25.9989 54.6673 25.9989C55.5145 25.9989 56.0966 25.7278 56.4125 25.1861C56.7284 24.6451 56.8864 23.8112 56.8864 22.6844V12.8516H59.1054V22.9014C59.1054 24.7927 58.7316 26.1683 57.9851 27.0275C57.238 27.8867 56.103 28.3165 54.5808 28.3165C53.6897 28.3165 52.9573 28.1648 52.3828 27.8614L52.3834 27.8608Z"
              fill="#06080D" />
            <path
              d="M66.4746 15.1257H60.3984V12.8516H74.7699V15.1257H68.7153V28.2289H66.4746V15.1257Z"
              fill="#06080D" />
            <path
              d="M77.3336 14.1188C78.1807 13.2743 79.294 12.8521 80.673 12.8521H87.3307C88.7096 12.8521 89.8265 13.2349 90.6812 14C91.5354 14.7657 91.963 15.8772 91.963 17.3356C91.963 18.7941 91.55 19.9709 90.7239 20.6924C89.8978 21.4146 88.8243 21.7751 87.5027 21.7751H86.3391C85.592 21.7751 85.0064 21.9374 84.5834 22.2626C84.1598 22.5879 83.9474 23.1183 83.9474 23.8546C83.9474 24.6485 84.2025 25.1978 84.7127 25.5007C85.2222 25.8041 85.8868 25.9558 86.7053 25.9558H91.7688V28.23H86.2958C84.9601 28.23 83.8608 27.8654 82.9991 27.1361C82.1373 26.4069 81.7062 25.3136 81.7062 23.8552C81.7062 22.4838 82.0905 21.4117 82.8587 20.6389C83.6268 19.8668 84.7226 19.4804 86.1442 19.4804H87.4588C88.062 19.4804 88.5862 19.3287 89.0314 19.0258C89.4766 18.7223 89.6995 18.1525 89.6995 17.3145C89.6995 16.4764 89.448 15.9031 88.9454 15.5925C88.4423 15.282 87.8391 15.1268 87.1353 15.1268H80.9298C80.1827 15.1268 79.5544 15.2967 79.0442 15.6355C78.5341 15.9748 78.2796 16.6211 78.2796 17.5738V28.23H76.0605V17.7038C76.0605 16.1589 76.4841 14.9639 77.3318 14.1194L77.3336 14.1188Z"
              fill="#06080D" />
            <path
              d="M113.487 14.1188C114.335 13.2743 115.448 12.8521 116.827 12.8521H123.528C124.433 12.8521 125.233 13.0397 125.93 13.4149C126.627 13.7906 127.172 14.3429 127.568 15.0715C127.962 15.8007 128.16 16.6782 128.16 17.7032V28.2294H125.941V17.5521C125.941 16.6135 125.686 15.9748 125.177 15.6349C124.666 15.2961 124.038 15.1262 123.292 15.1262H117.086C116.339 15.1262 115.711 15.2961 115.2 15.6349C114.69 15.9742 114.436 16.6135 114.436 17.5521V28.2294H112.217V17.7032C112.217 16.1583 112.64 14.9633 113.488 14.1188H113.487Z"
              fill="#06080D" />
            <path
              d="M123.721 21.4717H116.676V23.7023H123.721V21.4717Z"
              fill="#06080D" />
            <path
              d="M95.3996 14.1188C96.2467 13.2743 97.3601 12.8521 98.739 12.8521H105.44C106.345 12.8521 107.145 13.0397 107.842 13.4149C108.539 13.7906 109.084 14.3429 109.48 15.0715C109.875 15.8007 110.072 16.6782 110.072 17.7032V28.2294H107.853V17.5521C107.853 16.6135 107.598 15.9748 107.089 15.6349C106.578 15.2961 105.95 15.1262 105.204 15.1262H98.9982C98.2511 15.1262 97.6227 15.2961 97.1126 15.6349C96.6024 15.9742 96.348 16.6135 96.348 17.5521V28.2294H94.1289V17.7032C94.1289 16.1583 94.5525 14.9633 95.4002 14.1188H95.3996Z"
              fill="#06080D" />
            <path
              d="M105.634 21.4717H98.5889V23.7023H105.634V21.4717Z"
              fill="#06080D" />
          </svg>
        </div>

        <p class="at-text-sm at-text-neutral-500">
          {{ 'footer_section_contraa_slogan' | transloco }}
        </p>

        <div class="social-links at-py-xl at-mt-lg">
          <ul class="at-flex at-gap-md">
            <!-- Facebook -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3054_5300)">
                    <path
                      d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                      fill="#111827" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3054_5300">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>

            <!-- Linkedin -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3054_5301)">
                    <path
                      d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                      fill="#111827" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3054_5301">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>

            <!-- Tiktok -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.0725 0H13.0278V16.3478C13.0278 18.2957 11.4722 19.8957 9.53626 19.8957C7.60034 19.8957 6.04469 18.2957 6.04469 16.3478C6.04469 14.4348 7.56577 12.8695 9.43257 12.8V8.69567C5.31872 8.7652 2 12.1391 2 16.3478C2 20.5913 5.38786 24 9.57085 24C13.7538 24 17.1416 20.5565 17.1416 16.3478V7.9652C18.6627 9.07827 20.5295 9.73913 22.5 9.77393V5.66957C19.4579 5.56522 17.0725 3.06087 17.0725 0Z"
                    fill="#111827" />
                </svg>
              </a>
            </li>

            <!-- Youtube -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.7609 7.1998C23.7609 7.1998 23.5266 5.54512 22.8047 4.81855C21.8906 3.8623 20.8688 3.85762 20.4 3.80137C17.0438 3.55762 12.0047 3.55762 12.0047 3.55762H11.9953C11.9953 3.55762 6.95625 3.55762 3.6 3.80137C3.13125 3.85762 2.10938 3.8623 1.19531 4.81855C0.473438 5.54512 0.24375 7.1998 0.24375 7.1998C0.24375 7.1998 0 9.14512 0 11.0857V12.9045C0 14.8451 0.239062 16.7904 0.239062 16.7904C0.239062 16.7904 0.473437 18.4451 1.19062 19.1717C2.10469 20.1279 3.30469 20.0951 3.83906 20.1982C5.76094 20.3811 12 20.4373 12 20.4373C12 20.4373 17.0438 20.4279 20.4 20.1889C20.8688 20.1326 21.8906 20.1279 22.8047 19.1717C23.5266 18.4451 23.7609 16.7904 23.7609 16.7904C23.7609 16.7904 24 14.8498 24 12.9045V11.0857C24 9.14512 23.7609 7.1998 23.7609 7.1998ZM9.52031 15.1123V8.36699L16.0031 11.7514L9.52031 15.1123Z"
                    fill="#111827" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="links at-mb-2xl">
        <a
          [routerLink]="'about' | navigation"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_about_link' | transloco }}</a
        >
        <a
          [routerLink]="'become-supplier' | navigation"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_become_a_supplier_link' | transloco }}</a
        >
        <a
          [routerLink]="'features' | navigation"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_features_link' | transloco }}</a
        >
        <a
          [routerLink]="'contact-us' | navigation"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_contact_us_link' | transloco }}</a
        >
      </div>
    </div>

    <div
      class="bottom-section at-flex at-flex-column at-justify-between at-gap-4xl">
      <div class="at-flex at-gap-lg">
        <a
          class="at-py-x-sm at-text-sm at-fw-medium at-text-neutral-900"
          href="#"
          >{{ 'footer_section_privacy_policy_link' | transloco }}</a
        >
        <a
          class="at-py-x-sm at-text-sm at-fw-medium at-text-neutral-900"
          href="#"
          >{{ 'footer_section_terms_conditions_link' | transloco }}</a
        >
      </div>
      <p class="at-py-x-sm at-text-neutral-700 at-text-x-sm">
        {{ 'footer_section_developed_by_link' | transloco }}
        <a
          class="at-fw-semibold at-text-supplier-500"
          href="https://www.areebtechnology.com/"
          target="_blank"
          >Areeb Technology</a
        >
      </p>
    </div>
  </div>
</footer>
