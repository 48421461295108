<section
  class="at-px-lg md:at-px-6xl xl:at-px-9xl at-text-center at-flex at-flex-column at-align-center">
  <div class="hero at-flex at-flex-column at-align-center">
    <h1
      class="at-fw-bold at-h4 md:at-display-lg at-mb-sm md:at-mb-2xl at-mt-7xl">
      {{ 'landing_page_hero_section_title_part_one' | transloco }}

      <span class="text-gradient">
        {{ 'landing_page_hero_section_title_part_two' | transloco }}
      </span>
    </h1>

    <p class="at-text-neutral-700 at-text-md md:at-text-lg">
      {{ 'landing_page_hero_section_subtitle' | transloco }}
    </p>
  </div>

  <div class="dashboard-img-container at-dir-ltr">
    <img
      src="/assets/images/landing/dashboard-xl.webp"
      alt="dashboard"
      fetchpriority="high"
      loading="eager"
      decoding="sync"
      srcset="
        /assets/images/landing/dashboard-sm.webp  440w,
        /assets/images/landing/dashboard-lg.webp 1024w,
        /assets/images/landing/dashboard-xl.webp 1440w
      " />
  </div>
</section>
