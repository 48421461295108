import { Component } from '@angular/core';
import { NavigationPipe } from '../../pipes/navigation/navigation.pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mars-footer',
  imports: [NavigationPipe, RouterLink, RouterLinkActive, TranslocoModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {}
