import { inject, Pipe, PipeTransform, signal } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';

@Pipe({
  name: 'navigation',
  standalone: true,
})
export class NavigationPipe implements PipeTransform {
  translationService = inject(TranslationService);
  currentLang = signal(this.translationService.currentLang());

  transform(value: string): string {
    return this.currentLang() + '/' + value;
  }
}
