<section class="at-px-lg md:at-px-6xl xl:at-px-9xl at-my-7xl">
  <div class="at-mb-5xl">
    <h3 class="at-text-lg at-fw-extra-bold at-text-primary-500 at-mb-md">
      {{ 'landing_page_how_it_works_section_title' | transloco }}
    </h3>
    <p class="at-text-sm at-text-neutral-700">
      {{ 'landing_page_how_it_works_section_description' | transloco }}
    </p>
  </div>

  <div class="at-flex at-flex-column at-gap-5xl">
    @for (info of howItWorks; track info.title; let index = $index) {
      <mars-landing-card-img
        [badgeLabel]="index + 1 + ''"
        [image]="info.image"
        [title]="info.title"
        [description]="info.description"
        [position]="info.position" />
    }
  </div>
</section>
