import { Component, inject } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslationService } from '../../services/translation/translation.service';
import { NavigationPipe } from '../../pipes/navigation/navigation.pipe';

@Component({
  selector: 'mars-top-navbar',
  imports: [TranslocoPipe, RouterLink, RouterLinkActive, NavigationPipe],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss',
})
export class TopNavbarComponent {
  translationService = inject(TranslationService);

  lang = this.translationService.currentLang;
  isMobileNavbarOpened = false;

  switchLanguage() {
    this.translationService.switchLanguage();
  }

  toggleMobileNavbar() {
    this.isMobileNavbarOpened = !this.isMobileNavbarOpened;
  }
}
